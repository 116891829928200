import React from 'react';
import { RouteObject } from 'react-router-dom';

const PaymentSuccess = React.lazy(() => import('@/pages/payment/payment-success'));
const HyExperiencesPaymentSuccess = React.lazy(
  () => import('@/pages/payment/payment-success/experiences')
);
const FlightsPaymentSuccess = React.lazy(() => import('@/pages/payment/payment-success/flights'));
const PaymentFailed = React.lazy(() => import('@/pages/payment/payment-failed'));
const GeneratedPayment = React.lazy(() => import('@/pages/generated-payment'));
const AuctionPayment = React.lazy(() => import('@/pages/auction-payment'));
const UpgradePayment = React.lazy(() => import('@/pages/upgrade-payment'));
const ClaimOrderPage = React.lazy(() => import('@/pages/claim-order'));
const ChargeSuccess = React.lazy(() => import('@/pages/payment/charge-success'));
const ChargeFailed = React.lazy(() => import('@/pages/payment/charge-failed'));
const SubscribeSuccess = React.lazy(() => import('@/pages/payment/subscribe-success'));
const SubscribeFailed = React.lazy(() => import('@/pages/payment/subscribe-failed'));
const FlightsPaymentFailed = React.lazy(() => import('@/pages/payment/payment-failed/flights'));

const AuctionPaymentSuccessPage = React.lazy(
  () => import('@/pages/payment/payment-success/auction')
);
import Error404 from '@/components/error/404';
import AppLayout from '@/App';

export const paymentRoutes: RouteObject[] = [
  {
    element: <AppLayout />,
    errorElement: <Error404 />,
    children: [
      {
        path: 'payment-success/:id',
        element: <PaymentSuccess />,
      },
      {
        path: 'payment-success/experiences/:id',
        element: <HyExperiencesPaymentSuccess />,
      },
      {
        path: 'payment-success/auctions/:id',
        element: <AuctionPaymentSuccessPage />,
      },
      {
        path: 'payment-success/flights/:id',
        element: <FlightsPaymentSuccess />,
      },
      {
        path: 'payment-failed/:id?',
        element: <PaymentFailed />,
      },
      {
        path: 'payment-failed/flights/:id?',
        element: <FlightsPaymentFailed />,
      },
      {
        path: 'claim-order/:token',
        element: <ClaimOrderPage />,
      },
      {
        path: 'charge-success/:id',
        element: <ChargeSuccess />,
      },
      {
        path: 'charge-failed/:id?',
        element: <ChargeFailed />,
      },
      {
        path: 'subscribe-success/:id',
        element: <SubscribeSuccess />,
      },
      {
        path: 'subscribe-failed/:id?',
        element: <SubscribeFailed />,
      },
    ],
  },
  {
    element: <AppLayout type='mini-header' />,
    path: 'generated-payment/:id',

    children: [
      {
        path: '',
        element: <GeneratedPayment />,
      },
    ],
  },
  {
    element: <AppLayout type='mini-header' />,
    path: 'generated-payment/:id',
    children: [
      {
        path: '',
        element: <GeneratedPayment />,
      },
    ],
  },
  {
    element: <AppLayout type='mini-header' />,
    path: 'upgrade-payment/:id',
    children: [
      {
        path: '',
        element: <UpgradePayment />,
      },
    ],
  },
  {
    element: <AppLayout type='mini-header' />,
    path: 'auction-payment/:id',

    children: [
      {
        path: '',
        element: <AuctionPayment />,
      },
    ],
  },
];
