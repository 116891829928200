import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { CloseIcon } from '@wbk/svg';
import { formatDate, formatPrice } from '@wbk/utils';
import { useCurrency } from '@wbk/currency';
import { useDeviceDetect } from '@wbk/hooks';
import useFilters from '@/context/filters/useSearch';
import useLocalization from '@/context/localization/useLocalization';
import SelectedCategories from './SelectedCategories';
import SelectedZones from './SelectedZones';
import SelectedTags from './SelectedTags';

const ExploreSelectedFilters = () => {
  const { locale } = useLocalization();
  const { isMobile } = useDeviceDetect();
  const { date, price, total, selectFilter, clearFilters } = useFilters();
  const { currency } = useCurrency();
  const { t } = useTranslation('common');

  const rangeDate = date?.split('_');
  const isRangeDate = rangeDate?.length === 2;
  const dateStyle = locale.startsWith('ar') ? 'long' : 'medium';
  const dateLocale = `${locale}-GB`;

  const rangePrice = price?.split('_');
  const isRangePrice = rangePrice?.length === 2;
  const from = rangePrice?.[0] || 0;
  const to = rangePrice?.[1] || 0;

  if (total <= 0) return null;

  return (
    <div className='mb-6 flex flex-wrap gap-2'>
      {date && (
        <span className='flex items-center gap-1 rounded-full border px-2 py-0.5 text-sm'>
          {isRangeDate
            ? `${formatDate(rangeDate[0], dateLocale, { dateStyle })} - ${formatDate(rangeDate[1], dateLocale, { dateStyle })}`
            : t(`common:${date}`)}
          <Button
            theme='white'
            shape='text'
            className='p-1.5'
            onClick={() => {
              selectFilter({ key: 'date', value: null, apply: !isMobile });
            }}
          >
            <CloseIcon className='h-3 w-3' />
          </Button>
        </span>
      )}

      {isRangePrice && (
        <span className='flex items-center gap-1 rounded-full border px-2 py-0.5 text-sm'>
          {`${formatPrice(from)} ${currency.shortCode} - ${formatPrice(to)} ${currency.shortCode}`}
          <Button
            theme='white'
            shape='text'
            className='p-1.5'
            onClick={() => {
              selectFilter({ key: 'price', value: null, apply: !isMobile });
            }}
          >
            <CloseIcon className='h-3 w-3' />
          </Button>
        </span>
      )}

      <SelectedCategories />
      <SelectedZones />
      <SelectedTags />

      {total > 1 && (
        <Button theme='white' shape='text' className='px-1 py-0 underline' onClick={clearFilters}>
          {t('common:clear_all')}
        </Button>
      )}
    </div>
  );
};

export default ExploreSelectedFilters;
