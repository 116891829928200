export const DIRIYAH_SEASON_SLUG = 'diriyah-season';

export const DIRIYAH_CSS = `

    @font-face {
        font-family: 'kermes';
        src: local('kermes'), url(/fonts/kermes/kermes-light.otf);
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'kermes';
        src: local('kermes'), url(/fonts/kermes/kermes-regular.otf);
        font-weight: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'kermes';
        src: local('kermes'), url(/fonts/kermes/kermes-medium.otf);
        font-weight: semi-bold;
        font-display: swap;
    }

    @font-face {
        font-family: 'kermes';
        src: local('kermes'), url(/fonts/kermes/kermes-bold.otf);
        font-weight: bold; 
        font-display: swap;
    }

    #main, .prose {
        --main-bg: 32 48% 95%;
        --main-color: 0 0% 0%;

        font-family: 'kermes', sans-serif;
        background: hsl(var(--main-bg));
        color: hsl(var(--main-color));

        --color-primary-100: 28 35% 91%;
        --color-primary-200: 28 35% 81%;
        --color-primary-300: 28 35% 71%;
        --color-primary-400: 28 35% 61%;
        --color-primary-500: 28 35% 41%;
        --color-primary-600: 28 35% 35%;
        --color-primary-700: 28 35% 28%;
        --color-primary-800: 28 35% 21%;
        --color-primary-900: 28 35% 15%;
        --color-primary-contrast: 0 0% 100%;

        --color-action-100: 0 0% 10%;
        --color-action-200: 0 0% 7%;
        --color-action-300: 0 0% 5%;
        --color-action-400: 0 0% 3%;
        --color-action-500: 0 0% 0%;
        --color-action-600: 0 0% 0%;
        --color-action-700: 0 0% 0%;
        --color-action-800: 0 0% 0%;
        --color-action-900: 0 0% 0%;
        --color-action-contrast: 0 0% 100%;

        --color-text-primary: 0 0% 0%;
        --color-text-secondary: 240 10% 36%;
        --color-text-tertiary: 240 5% 65%;
        --color-text-disabled: 0 0 30%;
        --color-text-link: var(--color-primary-500);

        --input-bg: 0 0% 100%;
        --input-bg-hover: 0 0% 95%;
        --input-bg-disabled: 0 0% 80%;
        --input-border: 0 0% 80%;
        --input-border-focus: 0 0% 0%;
        --input-popover-bg: 0 0% 95%;

        --paper-border: 36 5% 78%;
        --paper-bg: var(--main-bg);
        --paper-bg-level-1: 32 48% 92%;

        --bg-body: var(--main-bg);
        --bg-body-level-1: 32 48% 92%;
        --bg-body-dark: 0 0% 0%;
        --bg-body-light: var(--main-bg);
    }
 
        
    @keyframes slideEn{
        100% {transform: translateX(-50%);}
    }

    @keyframes slideAr{
        100% {transform: translateX(50%);}
    }

`;
