import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentfulEvent, useGetEventsList } from '@wbk/contentful/api';
import { EventCarousel } from '@wbk/contentful';
import useLocalization from '@/context/localization/useLocalization';

type Props = {
  event: ContentfulEvent;
};

const SimilarEvents = ({ event }: Props) => {
  const { t } = useTranslation('event');
  const { countryCode, detectLoading } = useLocalization();
  const { lang } = useParams<{ lang: Language }>();
  const zoneSlug = event.zone?.slug;
  const { data: similars } = useGetEventsList(
    {
      lang,
      limit: 5,
      where: {
        slug_not: event.slug,
        zone: { slug: zoneSlug },
        ...(countryCode ? { location: { countryCode } } : {}),
      },
    },
    {
      enabled: !detectLoading,
    }
  );

  if (!similars?.items?.length) return null;

  return (
    <div className='border-paper space-y-4 border-t py-12 [&>section>div>div>h2]:text-2xl [&>section]:space-y-4 [&>section]:py-0'>
      <EventCarousel
        section={{
          categoriesCollection: {
            items: [],
          },
          contentCollection: {
            items: similars.items as ({ __typename: 'Event' } & ContentfulEvent)[],
          },
          title: t('event:you_might_also_like'),
          id: 'similar-events',
          webLinksCollection: {
            items: [],
          },
        }}
      />
    </div>
  );
};

export default SimilarEvents;
