import { RouteObject } from 'react-router-dom';
import OrganizerDetailPage from '@/pages/organizer/slug';

export const organizerRoutes: RouteObject[] = [
  {
    path: 'organizer/:slug',
    element: <OrganizerDetailPage />,
  },
  {
    path: 'organizers/:slug',
    element: <OrganizerDetailPage />,
  },
];
