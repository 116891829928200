import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { isArabic } from '@wbk/utils';
import { AdSlotVideo } from '@smc/ads';
import { useDeviceDetect } from '@wbk/hooks';
import Seo from '@/components/headers/seo';
import FiltersProvider from '@/context/filters/provider';
import ExploreLocationFilter from '@/components/explore/filters/Location';
import ExploreFilters from '@/components/explore/filters';
import ExploreSelectedFilters from '@/components/explore/filters/Selected';
import ExploreListing from '@/components/explore/ExploreListing';
import AppSmartBanner from '@/components/app/SmartBanner';

const SearchPage = () => {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const { isDesktop } = useDeviceDetect();
  const search = searchParams.get('q');
  const country = searchParams.get('country');
  const isArabicSearch = isArabic(search);
  const lang = isArabicSearch ? 'ar-SA' : 'en-US';

  return (
    <>
      <AppSmartBanner argument='search' />

      <FiltersProvider>
        <Seo title={t('common:search_result_of', { search })} />

        {!!search && (
          <section className='container pb-4 pt-12'>
            <h1 className='text-2xl font-semibold md:text-3xl'>
              {t('common:search_result_of', { search })}
            </h1>
          </section>
        )}

        <div className='container grid grid-cols-10 gap-4 py-6 xl:grid-cols-4'>
          <aside className='col-span-full hidden h-full w-full lg:col-span-3 lg:block xl:col-span-1'>
            <ExploreLocationFilter />
            {isDesktop && <AdSlotVideo className='sticky top-20 z-20' />}
            <ExploreFilters />
          </aside>
          <section className='col-span-full w-full lg:col-span-7 xl:col-span-3'>
            <ExploreFilters mobile />
            <ExploreSelectedFilters />
            <ExploreListing predefined={{ lang, locationGlobal: !country }} />
          </section>
        </div>
      </FiltersProvider>
    </>
  );
};

export default SearchPage;
