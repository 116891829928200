import { RouteObject, createBrowserRouter, redirect } from 'react-router-dom';
import { supportedLngs } from '@/i18n/config';
import { getDetectedLanguage } from '@/i18n/detected';

import Home from '@/pages/Home';
import Error404 from '@/components/error/404';
import Error500 from '@/components/error/500';
import AppLayout from '../App';

import { exploreRoutes } from './explore';
import { appRoutes } from './app';
import { eventDetailRoutes } from './events';
import { auctionBookingRoutes, eventBookingRoutes } from './booking';
import { paymentRoutes } from './payment';
import { authRoutes } from './auth';
import { profileRoutes } from './profile';
import { infoRoutes } from './info';
import { customPagesRoutes } from './customPages';
import { geaRoutes } from './gea';
import { zonesRoutes } from './zone';
import { searchRoutes } from './search';
import { referralRoutes } from './referral';
import { organizationRoutes } from './organizations';
import { subscriptionRoutes } from './subscriptions';
import { flightsRoutes } from './flights';
import { surveyRoutes } from './survey';
import { diriyahPagesRoutes } from './diriyah';
import { organizerRoutes } from './organizer';

export const router = createBrowserRouter([
  {
    path: '/:lang',
    element: <AppLayout />,
    loader: async ({ request }) => {
      const path = new URL(request.url).pathname;
      const firstSegment = (path?.split('/')?.[1] || '') as Language;
      const isValidLang = supportedLngs.includes(firstSegment);

      if (!isValidLang) {
        const resolved = await getDetectedLanguage();
        const search = new URL(request.url)?.searchParams?.toString();
        const safeSearch = search ? `?${search}` : '';
        return redirect(`/${resolved}${path}${safeSearch}`);
      }
      return null;
    },
    children: [
      {
        errorElement: <Error404 />,
        children: (
          [
            {
              path: '',
              element: <Home />,
              errorElement: <Error500 />,
            },
          ] as RouteObject[]
        ).concat(
          infoRoutes,
          exploreRoutes,
          eventDetailRoutes,
          organizationRoutes,
          profileRoutes,
          zonesRoutes,
          searchRoutes,
          referralRoutes,
          surveyRoutes,
          diriyahPagesRoutes,
          organizerRoutes,
          // Must be last
          customPagesRoutes
        ),
      },
    ],
  },
  {
    path: '/:lang',
    element: <AppLayout type='booking' />,
    children: [
      {
        errorElement: <Error404 />,
        children: eventBookingRoutes,
      },
    ],
  },
  {
    path: '/:lang',
    element: <AppLayout type='auth' />,
    children: [
      {
        errorElement: <Error404 />,
        children: authRoutes.concat(appRoutes),
      },
    ],
  },
  {
    path: '/:lang',
    element: <AppLayout type='mini-header' />,
    children: auctionBookingRoutes,
  },
  {
    path: '/:lang',
    errorElement: <Error404 />,
    children: paymentRoutes.concat(subscriptionRoutes, flightsRoutes),
  },
  ...geaRoutes,
  {
    path: '/',
    loader: async ({ request }) => {
      const resolved = await getDetectedLanguage();
      const search = new URL(request.url)?.searchParams?.toString();
      const safeSearch = search ? `?${search}` : '';
      return redirect(`/${resolved}${safeSearch}`);
    },
  },
  {
    path: '*',
    element: <AppLayout />,
    loader: async ({ request }) => {
      const path = new URL(request.url).pathname;
      const firstSegment = (path?.split('/')?.[1] || '') as Language;
      const isValidLang = supportedLngs.includes(firstSegment);

      if (!isValidLang) {
        const resolved = await getDetectedLanguage();
        const search = new URL(request.url)?.searchParams?.toString();
        const safeSearch = search ? `?${search}` : '';
        return redirect(`/${resolved}${path}${safeSearch}`);
      }
      return null;
    },
    children: [
      {
        path: '*',
        element: <Error404 />,
      },
    ],
  },
]);
