import { useTranslation } from 'react-i18next';
import { BottomSheet, Button, Modal, RadioGroup } from '@wbk/ui';
import { WorldOutlinedIcon } from '@wbk/svg';
import { useDeviceDetect } from '@wbk/hooks';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useCurrency } from '@wbk/currency';
import { languages } from '@/i18n/config';
import useLocalization from '@/context/localization/useLocalization';

type FormValues = { locale: string; currency: string; loading: boolean };

const LanguageCurrencySwitch = () => {
  const { isMobile } = useDeviceDetect();
  const methods = useForm<FormValues>();

  return (
    <FormProvider {...methods}>
      {isMobile ? (
        <BottomSheet
          ignoreHistory
          className='z-[100]'
          render={({ open }) => <Trigger open={open} />}
          body={({ close }) => <Body close={close} />}
          stickyFooter={() => <Footer />}
        />
      ) : (
        <Modal
          ignoreHistory
          closeButton
          size='7xl'
          render={({ open }) => <Trigger open={open} />}
          body={({ close }) => <Body close={close} />}
          stickyFooter={() => <Footer />}
        />
      )}
    </FormProvider>
  );
};

const Trigger = ({ open }: { open: () => void }) => {
  const { currency } = useCurrency();

  return (
    <Button
      shape='text'
      theme='white'
      className='text-text divide-paper items-center divide-x p-0 py-1 font-sans uppercase leading-none rtl:divide-x-reverse'
      onClick={open}
      data-testid='lang-switcher'
      role='menuitem'
    >
      <WorldOutlinedIcon className='h-4 w-4' />
      <span className='px-1'>{currency.shortCode}</span>
    </Button>
  );
};

const Body = ({ close }: { close: () => void }) => {
  const { locale, setLocale } = useLocalization();
  const { currency, currencies, setCurrency } = useCurrency();
  const { t } = useTranslation('common');
  const { control, handleSubmit, setValue } = useFormContext<FormValues>();

  const onSubmit = async (data: FormValues) => {
    try {
      setValue('loading', true);
      const currentLang = locale;

      setCurrency(data.currency);
      if (data.locale !== currentLang) {
        await setLocale(data.locale);
      }
      close();
    } catch (error) {
      // under investigation if really helpful or not
      // window.location.reload();
    } finally {
      setValue('loading', false);
    }
  };

  return (
    <form
      id='localization-selection-form'
      className='space-y-10 p-6 pb-24'
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className='text-3xl font-medium'>{t('common:nav.preferences')}</h2>
      <Controller
        name='locale'
        defaultValue={locale}
        control={control}
        render={({ field }) => (
          <RadioGroup
            {...field}
            label={<div className='mb-3 text-xl'>{t('common:nav.language')}</div>}
            groupClassName='flex space-y-0 items-center gap-4'
            className='px-3'
            options={languages.map((lang) => ({ text: t(`common:${lang}`), value: lang }))}
          />
        )}
      />

      <Controller
        name='currency'
        defaultValue={currency.shortCode}
        control={control}
        render={({ field }) => (
          <RadioGroup
            {...field}
            label={<div className='mb-3 text-xl'>{t('common:nav.currency')}</div>}
            groupClassName='flex space-y-0 gap-4 flex-wrap'
            className='basis-full px-3 md:basis-[calc(50%-16px)] lg:basis-[calc(33%-16px)]'
            options={currencies.map((currency) => {
              const code = currency.shortCode;
              const countryCode = code.slice(0, 2);
              return {
                text: (
                  <div className='flex items-center gap-2'>
                    <img
                      className='h-4 w-6 shrink-0'
                      src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${countryCode.toLowerCase()}.png`}
                      alt=''
                    />
                    <div>
                      <p>{currency.name}</p>
                      <p className='text-text-tertiary'>
                        {code}
                        {currency.symbol && ` - ${currency.symbol}`}
                      </p>
                    </div>
                  </div>
                ),
                value: code,
              };
            })}
          />
        )}
      />
    </form>
  );
};

const Footer = () => {
  const { t } = useTranslation('common');
  const { watch } = useFormContext<FormValues>();

  const loading = watch('loading');

  return (
    <div className='flex justify-end p-4 lg:px-6'>
      <Button type='submit' className='px-6' form='localization-selection-form' loading={loading}>
        {t('common:nav.confirm')}
      </Button>
    </div>
  );
};

export default LanguageCurrencySwitch;
