import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetZoneDetail } from '@wbk/contentful/api';
import { ZoneBreadcrumb, ZoneHeroSection, ZonePageSkeleton } from '@wbk/contentful';
import { useAnalytics } from '@wbk/analytics';
import Seo from '@/components/headers/seo';
import Error404 from '@/components/error/404';
import useLocalization from '@/context/localization/useLocalization';
import PageSection from '@/components/section';
import { DIRIYAH_CSS, DIRIYAH_SEASON_SLUG } from '@/constants/diriyah';
import AppSmartBanner from '@/components/app/SmartBanner';

const ZoneDetailPage = () => {
  const { locale } = useLocalization();
  const { slug } = useParams<{ slug: string }>();
  const { viewItemEvent } = useAnalytics();

  const { data, isLoading } = useGetZoneDetail({
    lang: locale,
    limit: 1,
    where: { slug },
  });
  const zone = data?.[0];

  useEffect(() => {
    // GA4 Event
    if (zone) {
      viewItemEvent({
        event: {
          _id: zone.id,
          slug: slug,
          title: zone.title,
          startingPrice: zone.startingPrice,
          zone: zone.slug,
          currencyCode: zone.currencyCode,
          category: zone.category?.slug || 'N/A',
          poster: zone.banner?.url || '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone]);

  if (isLoading) {
    return <ZonePageSkeleton />;
  }

  if (!zone) {
    return <Error404 />;
  }

  return (
    <>
      {zone.season?.slug === DIRIYAH_SEASON_SLUG && <style>{DIRIYAH_CSS}</style>}

      {zone.backgroundColor && <style>{`main { background-color:${zone.backgroundColor};}`}</style>}

      <Seo
        title={zone.title}
        description={zone.description}
        image={zone.featuredBanner?.url || zone.banner?.url}
      />
      <AppSmartBanner argument={`zone/${zone.slug}`} />

      <section className='container pt-6'>
        <ZoneBreadcrumb zone={zone} />
      </section>

      <ZoneHeroSection zone={zone} />

      {zone.webSectionsCollection?.items?.map((section, idx) => (
        <PageSection key={section.__typename + idx} section={section} />
      ))}
    </>
  );
};

export default ZoneDetailPage;
