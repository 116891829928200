export const HARRY_POTTER_SLUG = 'harry-potter-rs-24';

export const HARRY_POTTER_CSS = `

    @font-face {
        font-family: 'gentium';
        src: local('gentium'), url(/fonts/gentium/GenBasR.ttf);
        font-weight: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'gentium';
        src: local('gentium'), url(/fonts/gentium/GenBasB.ttf);
        font-weight: bold; 
        font-display: swap;
    }

    #main, .prose {

        --main-bg: 230 45% 16%;
        --main-color: 0 0% 100%;

        font-family: 'gentium', sans-serif;
        background: hsl(var(--main-bg));
        color: #E4E4E7;

        --color-primary-100: 240 100 97;
        --color-primary-200: 240 100 92;
        --color-primary-300: 240 100 84;
        --color-primary-400: 240 100 77;
        --color-primary-500: 240 100 67; 
        --color-primary-600: 240 100 57;
        --color-primary-700: 240 100 47;
        --color-primary-800: 240 100 37;
        --color-primary-900: 240 100 27;
        --color-primary-contrast: 0 0% 100%;
        
        --color-text-primary: 0 0% 100%;
        --color-text-secondary: 0 0% 0%;
        --color-text-tertiary: 240 5% 65%;
        --color-text-disabled: 0 0 30%;
        --color-text-link: var(--color-primary-500);


        --input-bg: 0 0% 0% / 20%; 
        --input-bg-hover: 0 0% 0% / 15%; 
        --input-bg-disabled: 0 0% 0% / 10%;
        --input-border: 0 0% 80%;
        --input-border-focus: 0 0% 0%;
        --input-popover-bg: 0 0% 95%;
        --paper-border: 36 5% 78%;

        --bg-body: hsl(var(--main-bg));
        --bg-body-level-1: 230 45% 16%;
    }

    @keyframes hover {
        0%,
        100% {
            transform: translateY(-10%) translateX(-50%) scaleX(-1);
        }
        50% {
            transform: translateY(-15%) translateX(-50%) scaleX(-1);
        }
    }

     .owl-animation {
        animation: hover 3s ease-in-out infinite;
        animation-delay: 0s, 2s;
    }
`;

export const areas = [
  'hall',
  'broomsticks',
  'forest',
  'quidditch',
  'courtyard',
  'gift_shop',
  'entrance_tunnel',
  'entrance_lobby',
  'model_room',
  'grand_staircase',
  'wizarding_world_shop',
] as const;

export type Area = (typeof areas)[number];
