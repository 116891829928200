import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { AppsDownload, Paper, Typography } from '@wbk/ui';
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL, HUAWEI_STORE_URL } from '@/constants/apps';

type Props = {
  className?: string;
};

const DownloadAppBanner = ({ className }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <section data-testid='download_app_section' className={twMerge('container', className)}>
      <Paper className='rounded-L my-10 flex flex-col gap-6 overflow-hidden lg:flex-row'>
        <div className='flex-1 lg:self-center lg:px-6 lg:py-8'>
          <Typography as='h2' className='mb-2' variant='heading-XXL'>
            {t('common:download_app_win')}
          </Typography>

          <Typography as='h2' className='mb-6 max-w-xl' variant='body-L' color='text-secondary'>
            {t('common:download_app_win_desc')}
          </Typography>

          <AppsDownload
            appstore={APP_STORE_URL}
            playstore={GOOGLE_PLAY_STORE_URL}
            huawei={HUAWEI_STORE_URL}
          />
        </div>

        <div className='flex items-center justify-center self-start rounded-xl bg-white p-3 lg:self-center'>
          <img src='/images/app/qr-code.png' alt='QR Code' width={110} height={110} />
        </div>

        <img className='-my-4 mx-auto' src='/images/app/iphones.png' alt='' />
      </Paper>
    </section>
  );
};

export default DownloadAppBanner;
