import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentfulEvent, useGetShows } from '@wbk/contentful/api';
import { EventCarousel } from '@wbk/contentful';
import useLocalization from '@/context/localization/useLocalization';
type Props = {
  event: ContentfulEvent;
};

const SimilarShows = ({ event }: Props) => {
  const { t } = useTranslation('event');
  const { lang } = useParams<{ lang: Language }>();
  const { countryCode, detectLoading } = useLocalization();
  const zoneSlug = event.zone?.slug;
  const { data: similars } = useGetShows(
    {
      lang,
      limit: 5,
      where: {
        slug_not: event.slug,
        zone: { slug: zoneSlug },
        ...(countryCode ? { location: { countryCode } } : {}),
      },
    },
    {
      enabled: !detectLoading,
    }
  );

  if (!similars?.items?.length) return null;

  return (
    <div className='space-y-4 border-t border-white/20 py-12 [&>section]:py-0'>
      <h2 className='container text-2xl font-semibold'>{t('event:you_might_also_like')}</h2>
      <EventCarousel
        section={{
          categoriesCollection: {
            items: [],
          },
          contentCollection: {
            items: similars.items as ({ __typename: 'Shows' } & ContentfulEvent)[],
          },
          title: '',
          id: 'similar-events',
          webLinksCollection: {
            items: [],
          },
        }}
      />
    </div>
  );
};

export default SimilarShows;
