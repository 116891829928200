import i18next, { defaultLang } from './config';

export const getDetectedLanguage = async () => {
  if (i18next.isInitialized) {
    return i18next.resolvedLanguage || defaultLang;
  }

  return new Promise<string>((resolve) => {
    i18next.on('initialized', () => {
      resolve(i18next.resolvedLanguage || defaultLang);
    });
  });
};
