import { Helmet } from 'react-helmet';

type Props = {
  /**
   * No need to add the `webook://` prefix.
   */
  argument: string;
};

const AppSmartBanner = ({ argument }: Props) => {
  return (
    <Helmet>
      <meta name='apple-itunes-app' content={`app-id=6468667896, app-argument=${argument}`} />
    </Helmet>
  );
};

export default AppSmartBanner;
