import { RouteObject } from 'react-router-dom';
import WebviewLogin from '@/pages/webview';
import AppCheckRedirect from '@/components/app/AppCheckRedirect';

export const appRoutes: RouteObject[] = [
  {
    path: 'webview',
    element: <WebviewLogin />,
    loader: ({ request }) => {
      const searchParams = new URL(request.url).searchParams;
      const redirect = searchParams.get('redirect') || '/';
      return { redirect };
    },
  },
  {
    path: 'deeplink',
    element: <AppCheckRedirect />,
  },
  {
    path: ':type/:slug/book/app',
    element: <WebviewLogin />,
  },
];
