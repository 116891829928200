import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createCookie, readCookie } from '@wbk/utils';
import { useGeoLocation, useGetSupportedCurrencies } from '@wbk/api/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { defaultLang, shortLangsMap, supportedLngs } from '@/i18n/config';
import Context, { FALLBACK_COUNTRY, FALLBACK_CURRENCY } from './context';

type Props = {
  children: React.ReactNode;
};

const LocalizationProvider = ({ children }: Props) => {
  const { i18n } = useTranslation('common');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lang } = useParams<{ lang: Language }>();
  const validLang = lang && supportedLngs.includes(lang) ? lang : defaultLang;
  const shortLang = shortLangsMap[validLang] || 'en';
  const savedLocation = readCookie('location') || '';
  const savedCurrency = readCookie('currency') || '';
  // Error is ignored here
  const { data, isPending } = useGeoLocation({
    enabled: !savedLocation,
    initialData: { country_name: savedLocation, currency: savedCurrency, is_supported: true },
  });
  const { data: currencies } = useGetSupportedCurrencies({ lang });

  const validCode = data?.country_name && /^[A-Z]{2}$/.test(data.country_name);
  const detectedCountryCode = data?.country_name || FALLBACK_COUNTRY;
  const detectedAndValid = validCode ? data?.country_name : FALLBACK_COUNTRY;
  const isSupportedCountry = typeof data?.is_supported === 'boolean' ? data?.is_supported : true;
  const countryCode = isSupportedCountry ? detectedAndValid : null;
  // Priority to search param
  const paramCurrency = new URLSearchParams(window.location.search).get('currency')?.toUpperCase();
  const detectedCurrency = paramCurrency || savedCurrency || data?.currency || FALLBACK_CURRENCY;

  useEffect(() => {
    // Save the detected country code in a cookie for 24h
    if (!!data?.country_name && countryCode) {
      createCookie({ name: 'location', value: countryCode, expiration: 1 });
      createCookie({ name: 'currency', value: detectedCurrency, expiration: 1 });
    }
  }, [countryCode, isSupportedCountry, detectedCurrency, data?.country_name]);

  useLayoutEffect(() => {
    updateLocale(shortLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortLang]);

  const updateLocale = async (newLocale: string) => {
    await i18n.changeLanguage(newLocale);
    createCookie({ name: 'lang', value: newLocale, expiration: 30 });
    document.documentElement.lang = newLocale;
    document.documentElement.dir = newLocale.startsWith('ar') ? 'rtl' : 'ltr';
  };

  const setLocale = async (newLocale: string) => {
    const pathWithoutLang = pathname.replace(`/${lang}`, '');
    if (newLocale !== shortLang) {
      const search = new URLSearchParams(window.location.search).toString();
      await updateLocale(newLocale);
      const dest = `/${newLocale}${pathWithoutLang}${search ? `?${search}` : ''}`;
      navigate(dest);
    }
  };

  return (
    <Context.Provider
      value={{
        locale: shortLang,
        detectedCountryCode,
        countryCode,
        currencies: currencies || [],
        detectedCurrency,
        isSupportedCountry,
        fallbackCountry: FALLBACK_COUNTRY,
        detectLoading: isPending,
        setLocale,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default LocalizationProvider;
