import { Fragment, Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useWebsiteConfig } from '@wbk/contentful/api';
import { globalJsonLd, searchJsonLd, websiteJsonLd } from '@/components/headers/seo/json-ld';
import HideOnTawakkalna from '@/components/twk/HideOnTawakkalna';
import GlobalHead from '../headers/Index';
import Seo from '../headers/seo';
import FullpageAnimatedLogo from '../ui/spinners/FullpageAnimatedLogo';
import HideOnWebview from '../webview/HideOnWebview';
import Header from './header';
import Footer from './footer';

type Props = {
  type?: LayoutType;
};

const Layout = ({ type = 'main' }: Props) => {
  const { lang } = useParams();
  const { data } = useWebsiteConfig({ lang, slug: 'webook-config' });

  return (
    <Fragment>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            ...globalJsonLd,
            image: data?.seo?.image?.url || globalJsonLd.image,
          }),
        }}
      />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(websiteJsonLd),
        }}
      />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(searchJsonLd),
        }}
      />

      <GlobalHead />
      <Seo
        title={data?.seo.title}
        description={data?.seo?.description}
        keywords={data?.seo?.keywords}
        image={data?.seo?.image?.url}
      />

      <Suspense
        fallback={
          <header
            data-type={type}
            className='border-paper md:bg-body relative z-20 h-12 w-full border-b data-[type=booking]:h-9 md:h-16 data-[type=booking]:md:h-12'
          ></header>
        }
      >
        <HideOnTawakkalna>
          <HideOnWebview>
            {data ? (
              <Header config={data} type={type} />
            ) : (
              <header
                data-type={type}
                className='border-paper md:bg-body relative z-20 h-9 w-full border-b data-[type=booking]:h-9 md:h-16 data-[type=booking]:md:h-12'
              ></header>
            )}
          </HideOnWebview>
        </HideOnTawakkalna>
      </Suspense>

      <main id='main'>
        <Suspense fallback={<FullpageAnimatedLogo withLogo={false} />}>
          <Outlet />
        </Suspense>
      </main>

      <Suspense fallback={<></>}>
        <HideOnTawakkalna>
          <HideOnWebview>{data && <Footer config={data} type={type} />}</HideOnWebview>
        </HideOnTawakkalna>
      </Suspense>
    </Fragment>
  );
};

export default Layout;
