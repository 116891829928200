import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetZoneDetail } from '@wbk/contentful/api';
import { ZoneBreadcrumb, ZoneHeroSection, ZonePageSkeleton } from '@wbk/contentful';
import { useAnalytics } from '@wbk/analytics';
import Seo from '@/components/headers/seo';
import Error404 from '@/components/error/404';
import useLocalization from '@/context/localization/useLocalization';
import PageSection from '@/components/section';
import AppSmartBanner from '@/components/app/SmartBanner';
import ExploreListing from '@/components/explore/ExploreListing';
import ExploreSelectedFilters from '@/components/explore/filters/Selected';
import ExploreFilters from '@/components/explore/filters';
import FiltersProvider from '@/context/filters/provider';

const OrganizerDetailPage = () => {
  const { locale } = useLocalization();
  const { slug } = useParams<{ slug: string }>();
  const { viewItemEvent } = useAnalytics();

  const { data, isLoading } = useGetZoneDetail({
    lang: locale,
    limit: 1,
    where: { slug },
  });
  const zone = data?.[0];

  useEffect(() => {
    // GA4 Event
    if (zone) {
      viewItemEvent({
        event: {
          _id: zone.id,
          slug: slug,
          title: zone.title,
          startingPrice: zone.startingPrice,
          zone: zone.slug,
          currencyCode: zone.currencyCode,
          category: zone.category?.slug || 'N/A',
          poster: zone.banner?.url || '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone]);

  if (isLoading) {
    return <ZonePageSkeleton />;
  }

  if (!zone) {
    return <Error404 />;
  }

  return (
    <>
      {zone.backgroundColor && <style>{`main { background-color:${zone.backgroundColor};}`}</style>}

      <Seo
        title={zone.title}
        description={zone.description}
        image={zone.featuredBanner?.url || zone.banner?.url}
      />
      <AppSmartBanner argument={`organizers/${zone.slug}`} />

      <section className='container pt-6'>
        <ZoneBreadcrumb zone={zone} ignoreSeason />
      </section>

      <ZoneHeroSection zone={zone} />

      <FiltersProvider>
        <div className='container grid grid-cols-10 gap-4 py-6 xl:grid-cols-4'>
          <aside className='col-span-full hidden h-full w-full lg:col-span-3 lg:block xl:col-span-1 [&>div>hr:first-child]:lg:hidden'>
            <ExploreFilters />
          </aside>
          <section className='col-span-full w-full lg:col-span-7 xl:col-span-3'>
            <ExploreFilters mobile />
            <ExploreSelectedFilters />
            <ExploreListing predefined={{ zones: [zone.slug] }} />
          </section>
        </div>
      </FiltersProvider>

      {zone.webSectionsCollection?.items?.map((section, idx) => (
        <PageSection key={section.__typename + idx} section={section} />
      ))}
    </>
  );
};

export default OrganizerDetailPage;
