import React from 'react';
import { RouteObject } from 'react-router-dom';
import SPLPage from '@/pages/spl';
import SPLTeamsPage from '@/pages/spl/teams';
import { PALM_WORLD_EVENT_SLUG } from '@/constants/plam-world';
import { HARRY_POTTER_SLUG } from '@/constants/harry-potter';

const AhliFcPage = React.lazy(() => import('@/pages/events/ahli-fc'));
const IttihadPage = React.lazy(() => import('@/pages/events/ittihad'));
const JCSAPage = React.lazy(() => import('@/pages/events/jcsa'));
const JCSAEventGroupPage = React.lazy(() => import('@/pages/events/jcsa/group'));
const PageBySlug = React.lazy(() => import('@/pages/[slug]'));
const PalmWorldPage = React.lazy(() => import('@/pages/events/palm-world'));
const FormulaEPage = React.lazy(() => import('@/pages/events/formula'));
const HouseOfHypePage = React.lazy(() => import('@/pages/experiences/house-of-hype'));
const HarryPotterPage = React.lazy(() => import('@/pages/experiences/harry-potter'));

export const customPagesRoutes: RouteObject[] = [
  {
    path: 'experiences/house-of-hype',
    element: <HouseOfHypePage />,
  },
  {
    path: `experiences/${HARRY_POTTER_SLUG}`,
    element: <HarryPotterPage />,
  },
  {
    path: 'events/ahli-fc',
    element: <AhliFcPage />,
  },
  {
    path: 'ittihad',
    element: <IttihadPage />,
  },
  {
    path: 'events/jcsa-homepage',
    element: <JCSAPage />,
  },
  {
    path: 'events/saudi-cup-taif-season',
    element: <JCSAEventGroupPage slug='saudi-cup-taif-season' />,
  },
  {
    path: 'events/riyadh-racing-season',
    element: <JCSAEventGroupPage slug='riyadh-racing-season' />,
  },
  {
    path: 'events/saudi-cup-season',
    element: <JCSAEventGroupPage slug='saudi-cup-season' />,
  },
  {
    path: 'spl',
    element: <SPLPage />,
  },
  {
    path: 'spl-teams',
    element: <SPLTeamsPage />,
  },
  {
    path: `events/${PALM_WORLD_EVENT_SLUG}`,
    element: <PalmWorldPage />,
  },
  {
    path: 'events/formula-e-25-3242',
    element: <FormulaEPage />,
  },
  // Must be last
  {
    path: ':slug',
    element: <PageBySlug />,
  },
];
