import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAnalytics } from '@wbk/analytics';
import { useGetPackageDetail } from '@wbk/contentful/api';
import {
  EventDateTime,
  EventDetailSidebar,
  EventHeaderDetail,
  EventLocation,
  SpotlightSectionAsync,
} from '@wbk/contentful';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { eventJsonLd } from '@/components/headers/seo/json-ld';
import Seo from '@/components/headers/seo';
import PackageDescription from '@/components/packages/Description';
import Error404 from '@/components/error/404';
import { parseContentfulToAnalyticsEvent } from '@/util/events';
import { DIRIYAH_SEASON_SLUG } from '@/constants/diriyah';

const PackagesDetailPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data: event, isLoading } = useGetPackageDetail({
    lang,
    limit: 1,
    where: { slug },
  });

  const { viewItemEvent } = useAnalytics();

  // GA4 Event
  useEffect(() => {
    if (event) {
      viewItemEvent({
        event: parseContentfulToAnalyticsEvent(event),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!event) {
    return <Error404 />;
  }

  return (
    <>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventJsonLd(event, `/${lang}/packages/${slug}`)),
        }}
      />

      <Seo
        title={event.seo?.title || event.title}
        description={event.seo?.description}
        image={event.seo?.image?.url || event.image11.url}
      />

      <EventHeaderDetail event={event} />

      <section className='container flex flex-col-reverse gap-6 py-8 lg:flex-row'>
        <div className='grow space-y-4'>
          <div className='border-body-level-1 flex flex-col flex-wrap gap-6 rounded-xl border p-4 sm:flex-row sm:gap-4 [&>div]:sm:basis-60'>
            <EventDateTime schedule={event.schedule} className='grow' />
            <EventLocation
              location={event.location}
              isOnline={event.isStreamingEvent}
              className='grow'
            />
          </div>
          <PackageDescription event={event} />
        </div>
        <aside className='mx-auto w-full shrink-0 grow space-y-6 lg:max-w-md'>
          <EventDetailSidebar event={event} />
        </aside>
      </section>
      {event.season?.slug !== DIRIYAH_SEASON_SLUG && (
        <SpotlightSectionAsync
          id={
            event.location?.countryCode
              ? `webook_event_spotlight_${event.location.countryCode}`
              : undefined
          }
        />
      )}
    </>
  );
};

export default PackagesDetailPage;
