import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetRestaurantDetail } from '@wbk/contentful/api';
import { CollapsableText, EventHeaderDetail, SpotlightSectionAsync } from '@wbk/contentful';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { restaurantJsonLd } from '@/components/headers/seo/json-ld';
import Seo from '@/components/headers/seo';
import SimilarRestaurants from '@/components/restaurants/SimilarRestaurants';
import Error404 from '@/components/error/404';
import RestaurantDetails from '@/components/restaurants/RestaurantDetails';
import IconQAList from '@/components/restaurants/IconQAList';
import MenuCollection from '@/components/restaurants/MenuCollection';
import ZoneEntry from '@/components/restaurants/ZoneEntry';
import RestaurantSummary from '@/components/restaurants/RestaurantSummary';
import PaymentMethods from '@/components/common/PaymentMethods';
import NeedHelp from '@/components/common/NeedHelp';
import JoinWebook from '@/components/common/JoinWebook';
import RestaurantLocation from '@/components/restaurants/RestaurantLocation';
import { DIRIYAH_CSS, DIRIYAH_SEASON_SLUG } from '@/constants/diriyah';
import AppSmartBanner from '@/components/app/SmartBanner';

const RetaurantsDetailPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data, isLoading } = useGetRestaurantDetail({
    lang,
    limit: 1,
    where: { slug },
  });
  const { t } = useTranslation(['common', 'event']);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!data) {
    return <Error404 />;
  }

  return (
    <>
      {data.season?.slug === DIRIYAH_SEASON_SLUG && <style>{DIRIYAH_CSS}</style>}

      <AppSmartBanner argument={`restaurantDetails/${data.sys.id}`} />

      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(restaurantJsonLd(data, `/${lang}/restaurants/${slug}`)),
        }}
      />

      <Seo
        title={data.seo?.title || data.title}
        description={data.seo?.description}
        image={data.seo?.image?.url || data.image11.url}
      />

      <EventHeaderDetail event={data} />

      <section className='container flex flex-col gap-6 space-y-6 py-8 lg:flex-row'>
        <div>
          <RestaurantDetails event={data} />
          <div className='my-6 space-y-6 lg:hidden'>
            <RestaurantSummary data={data} />
            <PaymentMethods withTabby />
          </div>
          <IconQAList
            data={data?.about}
            additionalItems={<RestaurantLocation location={data.location} className='md:hidden' />}
            iconsListClassName='flex flex-col flex-wrap md:flex-row md:gap-8 [&_h4]:leading-5'
            className='border-t-0'
          />
          <RestaurantLocation location={data.location} className='hidden md:block' />
          <MenuCollection data={data?.menuCollection} />
          <IconQAList
            data={data?.thingsToKnow}
            iconsListClassName='grid grid-cols-[repeat(auto-fill,minmax(90px,1fr))] gap-2 text-center text-[11px] [&_h4]:font-normal [&_h4]:leading-3 py-2 [&_qa-item]:py-3'
          />
          <IconQAList data={data?.attendees} />
          <ZoneEntry data={data} />

          {data.richTerms && (
            <div className='space-y-4 py-6'>
              <h2 className='text-xl font-semibold md:text-2xl'>
                {t('event:cancellation_refund_policy')}
              </h2>
              <CollapsableText
                minimum={4}
                text={data.richTerms}
                btnProps={{ 'aria-label': 'Read More About Terms' }}
                className='text-text text-sm'
              />
            </div>
          )}

          <div className='xl:hidden'>
            <NeedHelp />
          </div>
        </div>

        <aside className='hidden w-full max-w-md shrink-0 grow space-y-6 lg:block xl:max-w-xl'>
          <RestaurantSummary data={data} />
          <PaymentMethods withTabby />
          <NeedHelp />
          <JoinWebook />
        </aside>
      </section>
      {data.season?.slug !== DIRIYAH_SEASON_SLUG && (
        <SpotlightSectionAsync
          id={
            data.location?.countryCode
              ? `webook_event_spotlight_${data.location.countryCode}`
              : undefined
          }
        />
      )}

      <SimilarRestaurants event={data} />

      <div className='container mb-6 xl:hidden'>
        <JoinWebook />
      </div>
    </>
  );
};

export default RetaurantsDetailPage;
