import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, ShareModal } from '@wbk/ui';
import { GroupEvent } from '@wbk/api';
import { QueueView } from '@wbk/queue';
import { ContentfulMedia, GalleryWithThumbs } from '@wbk/contentful';
import { CurrencyPriceLabel } from '@wbk/currency';
import TicketingEventDateTime from '../events/TicketDateTime';

type Props = {
  event: GroupEvent;
};

const EventTitleDetails = ({ event }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation(['event']);
  const tickets = event.events.upcoming;
  const firstPrice = tickets[0]?.price || 0;
  const minPrice = tickets.reduce((min, event) => Math.min(min, event.price), firstPrice);

  return (
    <>
      <div className='space-y-4 pb-6'>
        <div className='pb-2 xl:hidden'>
          {event.gallery_images.length > 0 ? (
            <GalleryWithThumbs
              poster={{ url: event.poster }}
              carousel={event.gallery_images?.map((url) => ({ url }))}
            />
          ) : (
            <figure>
              <ContentfulMedia
                className='aspect-1 mx-auto w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:hidden'
                media={{
                  title: event.title,
                  url: event.poster,
                  height: 600,
                  width: 600,
                  contentType: 'image',
                  sys: {
                    id: '',
                    publishedAt: '',
                  },
                }}
                placeholder
              />
              <ContentfulMedia
                className='mx-auto hidden aspect-[3/1] w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:block'
                media={{
                  title: event.title,
                  url: event.poster,
                  height: 400,
                  width: 600,
                  contentType: 'image',
                  sys: {
                    id: '',
                    publishedAt: '',
                  },
                }}
                placeholder
              />
            </figure>
          )}
        </div>

        <div className='flex flex-col-reverse justify-between'>
          <div className='flex flex-col-reverse items-center gap-6 md:flex-row'>
            <h1 className='text-2xl sm:text-4xl'>{event.title}</h1>
          </div>
        </div>

        <QueueView />

        <div className='flex items-center justify-between gap-4 md:justify-start'>
          {minPrice > 0 && (
            <div className='bg-body-dark/5 rounded-md p-4 ltr:pr-14 rtl:pl-14'>
              <div className='flex items-center gap-1'>
                <span>{t('event:starts_from')}</span>
                <CurrencyPriceLabel
                  originalPrice={minPrice}
                  currency='SAR'
                  priceClassName='font-semibold text-lg'
                />
              </div>
              <p className='text-text-tertiary text-[11px]'>{t('event:vat_included')}</p>
            </div>
          )}

          <ShareModal
            data={{
              title: event?.title,
              text: event?.title,
            }}
            poster={event.poster}
          />
        </div>

        {tickets.length > 0 && (
          <div className='space-y-4 border-t border-white/20 py-6'>
            <h2 className='text-xl'>{t('event:select_your_ticket')}</h2>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3'>
              {tickets.map((item) => {
                return (
                  <Button
                    key={item._id}
                    as={Link}
                    to={`/${lang}/events/${item.slug}/book`}
                    queueProtected
                    className='flex w-full cursor-pointer items-start justify-between gap-2 rounded-md bg-white/5 px-3 py-4 hover:bg-white/10 active:bg-white/5'
                  >
                    <div className='space-y-2 text-start'>
                      <p className='text-sm font-semibold'>{item.title}</p>
                      <TicketingEventDateTime
                        startDateTime={item.start_date}
                        endDateTime={item.end_date}
                        className='text-text-tertiary text-[11px]'
                        showTime={false}
                      />
                    </div>
                    <img
                      src='/icons/common/arrow.svg'
                      width={20}
                      height={20}
                      className='rtl:rotate-180'
                      alt={t('common:back')}
                    />
                  </Button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EventTitleDetails;
